import ReactGA from 'react-ga4';

// Initialize GA4
ReactGA.initialize('G-RE5T93ZLEW');

// Track pageviews
export const trackPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};

// Track events
export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label
  });
};